const NAMESPACE = "ITEM_SEARCH_";

export const SET_AREA = `${NAMESPACE}SET_AREA`;
export const SET_KEYWORD = `${NAMESPACE}SET_KEYWORD`;
export const SET_KEYWORDS = `${NAMESPACE}SET_KEYWORDS`;
export const SET_KEYWORDS_SEARCH_TYPE = `${NAMESPACE}SET_KEYWORDS_SEARCH_TYPE`;
export const SET_EXCLUSIONS = `${NAMESPACE}SET_EXCLUSIONS`;
export const SET_MATERIAL = `${NAMESPACE}SET_MATERIAL`;
export const SET_PULSING = `${NAMESPACE}SET_PULSING`;
export const SET_SLOT = `${NAMESPACE}SET_SLOT`;
export const SET_WEIGHT = `${NAMESPACE}SET_WEIGHT`;
export const SET_WEIGHT_TYPE = `${NAMESPACE}SET_WEIGHT_TYPE`;
export const TOGGLE_ADVANCED_SEARCH = `${NAMESPACE}TOGGLE_ADVANCED_SEARCH`;
